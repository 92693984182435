import md5 from 'blueimp-md5';
import _ from 'lodash';

let SERVER = '/server';
if (process.env.NODE_ENV === 'development') {
  SERVER = 'http://localhost:8888/';
}

const USER_BROWSER_TOKEN = 'USER_BROWSER_TOKEN';


const BUILD = process.env.BUILT_FOR;
let ASSETS_SERVER = 'http://localhost:8888/';
if (BUILD === 'LIVE') {
  ASSETS_SERVER = '/server/';
}
if (BUILD === 'STAGE') {
  ASSETS_SERVER = '/server/';
}


export {
  BUILD, ASSETS_SERVER,
};
function s4() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}


/*
  returns unique GUID
  */
function guid() {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}


export default class Api {
static client;

static direction;

static async getClient() {
  return Api.request('GET_CONTENT', {  });
}

static async getHtmlContent(fileName) {
  return Api.request('GET_CONTENT_HTML', { fileName });
}

static async getJsonContent(fileName) {
  return Api.request('GET_CONTENT_JSON', { fileName });
}




static uuid() {
  let uuid = localStorage.getItem(USER_BROWSER_TOKEN);
  if (uuid != null) {
    return uuid;
  }
  uuid = guid();
  localStorage.setItem(USER_BROWSER_TOKEN, uuid);
  return uuid;
}

static async request(ACTION, pbody, formField = false) {
  const body = pbody || {};
  if (formField === false) {
    body.ACTION = ACTION;
    body.uuid = Api.uuid();
  }
  const resp = await fetch(`${SERVER}/bootstrap.php`, {
    method: 'POST',

    body: formField === false ? JSON.stringify(body) : body,
  });

  if (resp.headers.get('content-length') == '0') {
    throw new Error('Your session has timed out please login again.');
  }

  const val = await resp.json();
  if (val?.SESSION_OUT === true) {
    // location.replace("/admin");
  }

  return val;
}
}

const PLATFORM_cardinalhealth="cardinalhealth";
const PLATFORM_hackensack="hackensack";
const PLATFORM_optumeap="optumeap";
const PLATFORM_HCA='HCA';

export {
  SERVER,
  PLATFORM_cardinalhealth,
  PLATFORM_hackensack,
  PLATFORM_optumeap,
  PLATFORM_HCA


};
